import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

export default ({ data }) => {
    const { markdownRemark } = data
    const { html } = markdownRemark 

    return (
        <Layout>
            <div 
                className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto"
                dangerouslySetInnerHTML={{ __html: html }}></div>
        </Layout>
    )
}

export const query = graphql`
  {
    markdownRemark(fileAbsolutePath: {regex: "/privacy-policy/"}) {
      html
    }
  }
`
